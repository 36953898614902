import React, { Component } from 'react';
import NewsEvent from '../../data/blogdata.json';
class News extends Component {
  render() {
     let anchor = '#'
     let imgattr = 'image'
     let publicUrl = process.env.PUBLIC_URL
     let posts = NewsEvent.news
    return (
      <div className="related-posts">
        <div className="col-sm-8 offset-sm-2">
                  <div className="section-title text-center m-50px-b">
                     <h2 style={{color:'whitesmoke', textAlign:'center', fontSize:'40px'}}>News & Events</h2>
                  </div>
         </div>
         <div className="row justify-content-center" >
                  
            { posts.map( function( bloginfo, i ) {
               return <div key={i} className="col-lg-4 col-md-6 col-xs-12 d-flex align-items-stretch">
                  <div className="page-content" style={{boxShadow:"none"}}>
                     <div className="blog-post-img">
                        <img src={ publicUrl + bloginfo.image} alt={imgattr} />
                        <div className="blog-tag">
                        <a style={{color:'white'}}>{bloginfo.category}</a>
                        </div>
                     </div>
                     <div className="blog-content">
                        <div className="post-meta m-15px-b">
                           <ul>
                           
                           <li><a><i className="icofont-calendar"></i> {bloginfo.date}</a></li>
                           {/* <li><a href={anchor}><i className="icofont-speech-comments"></i> {bloginfo.comments}</a></li> */}
                           </ul>
                        </div>
                           <h2 style={{color:'black'}}>{bloginfo.title}</h2>
                           <p className="about-content">{bloginfo.content}</p>
                     
                        <a className="read-more" href={bloginfo.url} target="_blank" style={{color:'white'}}>Read more</a>
                     </div>
                  </div>
               </div>
            } ) }
         </div>
      </div>

    );
  }
}

export default News;
