import React, { Component } from 'react';
import homedata from '../../data/homedata.json';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import Modal from 'react-modal';
class Testimonials extends Component {


   constructor(props) {
      super(props);
      this.swiper = null;
      this.state = {
         showModal: false,
         selectedImage: '', // Stores the URL of the selected image
       };
    }
  
    componentDidMount() {
      // Initialize Swiper after the component has mounted
      this.initSwiper();
    }
  
    componentDidUpdate(prevProps, prevState) {
      // Reinitialize Swiper if component updates (e.g., new data)
      if (prevProps.data !== this.props.data) {
        this.destroySwiper();
        this.initSwiper();
      }
    }
  
    initSwiper() {
      this.swiper = new Swiper('.testimonialSwiper', {
        // Swiper configuration options
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true, // Enable loop to allow continuous sliding
        autoplay: {
          delay: 3000, // Time between each slide in milliseconds
          disableOnInteraction: false, // Allow auto slide even if user interacts with Swiper
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
         nextEl: '.swiper-button-next',
         prevEl: '.swiper-button-prev',
      },
        speed: 1000,
      });
    }
  
    destroySwiper() {
      if (this.swiper) {
        this.swiper.destroy();
        this.swiper = null;
      }
    }

  render() {
   let publicUrl = process.env.PUBLIC_URL
   let imgattr = 'image'
   return (
      <section id="ourBearers" className="p-100px-tb md-p-80px-tb position-relative testimonials" style={{minHeight:'100vh'}} >
         <div className="container p-50px-t" >
            <div className="row">
               <div className="col-sm-8 offset-sm-2">
                  <div className="section-title text-center m-50px-b">
                     <h2 style={{color:'whitesmoke', textAlign:'center', fontSize:'40px'}}>Committee Members</h2>
                     <p style={{color:"white"}}><i>(2023-2025)</i></p>
                  </div>
               </div>
            </div>
            <div className="row">
               
               <div className="col-lg-8 offset-lg-2 swiper-container testimonialSwiper p-50px-b">
                  <div className="swiper-wrapper">
                     { homedata.testimonial.map( ( data, i ) => {

                     return <div key={i} className="single-testimonial swiper-slide p-20px-r">
                        <div className="row ">
                           <div className="col-lg-2 col-md-3">
                              <img className="rounded-circle"  src={publicUrl+data.imgurl} alt={imgattr}/>
                           </div>
                           <div className="col-lg-10 col-md-9">
                              <p className="about-content">{data.content}</p>
                              <h5>{data.client}</h5>
                              <p className="designation">{data.designation}</p>                              
                           </div>
                        </div>
                     </div>
                     } ) }

                  </div>
                  <div className="swiper-pagination"></div>  
                
               </div>
                     
            </div>
            <div style={{textAlign:"center" , marginTop:"50px"}}><a className="btn btn-default btn-filled animated-btn m-10px-t" href="/orgChart" style={{borderRadius:'90px'}} >Organisation Structure</a></div>
            
         </div>
      </section>
   );
  }
}

export default Testimonials;
