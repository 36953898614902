import React from 'react';
import { LinkProps, NavLinkProps } from 'react-router-dom';



const ScrollLink = ({ to, children }) => {
  // Function to handle smooth scrolling when anchor links are clicked
  const scrollToSection = (event) => {
    event.preventDefault();
    const section = document.getElementById(to);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <a className="nav-link" href={`#${to}`} onClick={scrollToSection}>
      {children}
    </a>
  );
};

export default ScrollLink;
