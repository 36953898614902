import React, { useState } from 'react';
import homedata from '../../data/homedata.json';

const FeatureOne = () => {
  const [showMore, setShowMore] = useState(false);
  const { content, content2, btn1label  } = homedata.feature1;
  const paragraphs = content.split('\n\n');

  return (
    <section id="aboutUs" className="p-100px-tb featured-1 position-relative" style={{minHeight:'100vh'}}>
      {/* <div className="bordered-circle2"></div> */}
      <div className="container p-100px-t">
        
        <div className="row">
          
          <div className="col-sm-6 md-m-50px-b">
            <img className="moveUpDown" src={process.env.PUBLIC_URL + homedata.feature1.imgurl1} alt="" />
          </div>    
          <div className="col-sm-6">
            <div className="section-title">
              <h2 className="tlt1">{homedata.feature1.title}</h2>
            </div>
            <div style={{ textAlign: 'justify' }}>
                {paragraphs.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
            {showMore ? (
              <p style={{ textAlign: 'justify' }}>{content2}</p>
            ) : (
              <p style={{ textAlign: 'justify' }}>{content2.substring(0, 0)}</p>
            )}
            
            <button 
               onClick={() => setShowMore(!showMore)} 
               className="btn btn-default btn-filled animated-btn"
            >
               {showMore ?  'Show Less' : btn1label}
            </button>
          </div>
        </div>
      </div>

    </section>
    

    
  );
};

export default FeatureOne;
