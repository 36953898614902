import React, { Component } from 'react';
import homedata from '../../data/homedata.json';


class Feature extends Component {
  render() {
   let publicUrl = process.env.PUBLIC_URL
   let imgattr = 'image'
    return (
      <section id="ourBeliefs" className="p-100px-tb position-relative" style={{minHeight:'100vh'}}>
         {/* <div className="filled-circle"></div> */}
         <div className="container p-100px-t">
            <div className="row">
               <div className="col-sm-8 offset-sm-2">
                  <div className="section-title text-center m-50px-b">
                     <h2>{homedata.features.sectiontitle}</h2>
                  </div>
               </div>
            </div>
            <div className="container">
                  <div className="row">
                     {homedata.features.featurescontent.map( ( feature, i ) => 
                        <div key={i} className="col-sm-6 col-xs-12 d-flex align-items-stretch">
                           <div className="how-it-box text-center p-15px-lr p-50px-tb m-10px-t m-10px-b">
                              <i className={feature.icon}></i>
                              <h4>{feature.title}</h4>
                              <p style={{textAlign:'center'}}>{feature.content}</p>
                           </div>
                        </div>
                     )}   
                                  
                  </div>
                  
            </div>
         </div>
      </section>

    );
  }
}

export default Feature;
