import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";


import blogdata from "./data/blogdata.json";
import Singleblogdata from "./data/single-blogdata.json";
import Footerdata from "./data/footerdata.json";
import Homedata from "./data/homedata.json";



import Home from "./components/Home";
import Contact from "./components/components-home/Contact";
import Header from "./components/components-global/Header";
import Footer from "./components/components-global/Footer";
import Blogfull from "./components/components-blog/Blogfull";
import Blog2col from "./components/components-blog/blog-2-col";
import Blog3col from "./components/components-blog/blog-3-col";
import Blogfullleftsidebar from "./components/components-blog/blog-full-left-sidebar";
import Blogfullrightsidebar from "./components/components-blog/blog-full-right-sidebar";
import Singleblog from "./components/components-blog/sertu";
import Singleleftsidebar from "./components/components-blog/single-left-sidebar";
import Singlerightsidebar from "./components/components-blog/single-right-sidebar";
import CourtasyVisit from "./components/components-blog/courtasyvisit";
import ScrollToTop from "./components/components-global/ScrollToTop";
import About from "./components/components-blog/about-2";
import HAS from "./components/components-blog/Blogfull";
import HTAP from "./components/components-blog/htap";
import ORG from "./components/components-blog/orgChart";

class Root extends Component {
  render() {
    return (
      <div>
        <Router basename={process.env.PUBLIC_URL}>
            <div>
              {/* <Header /> */}
              <ScrollToTop />
              <Switch>
                <Route path="/" exact component={Home} />
                <Route
                  path="/about-2"
                  render={() => {
                    return <About data={Singleblogdata} />;
                  }}
                />
                <Route
                  path="/Contact"
                  render={() => {
                    return <Contact data={Homedata} />;
                  }}
                />
                <Route
                  path="/sertu"
                  render={() => {
                    return <Singleblog data={Singleblogdata} />;
                  }}
                />
                <Route
                  path="/Blogfull"
                  render={() => {
                    return <HAS data={blogdata} />;
                  }}
                />
                <Route
                  path="/htap"
                  render={() => {
                    return <HTAP data={blogdata} />;
                  }}
                /><Route
                path="/orgChart"
                render={() => {
                  return <ORG data={blogdata} />;
                }}
              />
                <Route
                  path="/blog-2-col"
                  render={() => {
                    return <Blog2col data={blogdata} />;
                  }}
                />
                <Route
                  path="/blog-3-col"
                  render={() => {
                    return <Blog3col data={blogdata} />;
                  }}
                />
                <Route
                  path="/blog-full-left-sidebar"
                  render={() => {
                    return <Blogfullleftsidebar data={blogdata} />;
                  }}
                />
                <Route
                  path="/blog-full-right-sidebar"
                  render={() => {
                    return <Blogfullrightsidebar data={blogdata} />;
                  }}
                />
                <Route
                  path="/single-left-sidebar"
                  render={() => {
                    return <Singleleftsidebar data={Singleblogdata} />;
                  }}
                />
                <Route
                  path="/single-right-sidebar"
                  render={() => {
                    return <Singlerightsidebar data={Singleblogdata} />;
                  }}
                />
                <Route
                  path="/courtasyvisit"
                  render={() => {
                    return <CourtasyVisit data={Singleblogdata} />;
                  }}
                />
              </Switch>
              <Footer data={Footerdata} />
            </div>
        </Router>
      </div>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
