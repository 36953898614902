import React, { Component } from 'react';
import Header2 from "../components-global/Header2";
import HeaderBottom from '../components-global/Header-bottom';

class Blogfull extends Component {

   render() {
      let anchor = '#'
      let propsData = this.props.data
      let blogs = propsData.blogs
      let headerPageTitle = propsData.pagetitle.blogfulltitle
      
      return (
      <div>
      <Header2 />
      <HeaderBottom/>
      <section id="blog-full" className="p-80px-tb gray-bg">
         <div className="container">
            <div className="row">
            { blogs.map( ( bloginfo,  ) => {

               let col = 'col-lg-6';
               if( bloginfo.fullwidth ) {
                  col = 'col-lg-12';
               }
               
               return (
                  
                  
                     <article className="page-content">
                        <div className="blog-post-img">
                           <img src={process.env.PUBLIC_URL+bloginfo.image} alt="" />
                           
                        </div>
                        <div className="blog-content">
                              <h3>What is Halal Assurance System (HAS)?</h3>

                              <div className="single-blog-post " style={{textAlign:'justify'}}>
                                 <p>The Halal Assurance System (HAS) is a structured method used to identify and control non-halal contamination in products and services, ensuring they meet halal and safety standards. It helps companies continuously improve their halal products and services by following the PDCA cycle (Plan, Do, Check, Act).</p>
                                 <p>In simpler terms, the HAS ensures that products and services are genuinely halal and pure by implementing a specific system for control. Only those with a halal certificate can meet the required standards and regulations for halal certification.</p>
                                 <p>The system has six principles and applications that guide companies in preserving the halal integrity of their products. For businesses in the food industry, understanding and implementing this system is crucial as it serves as a practical guide to obtaining halal certification from relevant authorities in Malaysia. However, it is the manufacturer's responsibility to develop and manage the HAS, aligning it with the entire supply chain process and meeting the requirements set by relevant authorities.</p>
                                 <h4 className="m-40px-t">The principles of the HAS are as follows:</h4>
                                 <p><b>1. Determination of Halal Critical Points</b> <br/> Identify and mark potential sources of contamination, known as halal critical points, throughout the entire supply chain. These points will be assessed for compliance with halal standards and policy statements.</p>
                                 <p><b>2. Development and Verification of Flow Chart</b> <br/> Create comprehensive flow charts that outline all steps involved in the supply chain process. These charts will be validated through on-site inspections to identify potential halal contaminations and design appropriate control measures.</p>
                                 <p><b>3. Implementation of Control Measures</b> <br/> Take necessary control measures in accordance with Malaysian standards to address any non-compliant issues that arise.</p>
                                 <p><b>4. Development of Corrective Actions</b> <br/> Develop, review, and implement corrective actions in cases of noncompliance. These actions must align with established procedures to maintain the halal integrity of products or processes.</p>
                                 <p><b>5. Documentation System and Management of  Records</b> <br/> Maintain an effective documentation and record-keeping system to provide evidence of conformity to requirements and the successful implementation of the HAS.</p>
                                 <p><b>6. Process Verification</b> <br/> Verify the effectiveness of the system through various checks, including reviewing records, monitoring system compliance, ensuring personnel adherence to operations, and auditing reports.</p>
                                 
                              </div>
                              <a className="read-more" href="https://www.halal.gov.my/v4/images/pdf/halalassurancesystem.pdf" target='_blank'>Read more</a>
                        </div>
                     </article>
               
               )

            } ) }

            </div>
            {/* <div className="row">
               <div className="col">
                  <nav className="m-25px-t app-pagenation">
                     <ul className="pagination">
                        <li className="page-item disabled">
                           <a className="page-link" href={anchor} tabIndex="-1"><i className="icofont-long-arrow-left"></i></a>
                        </li>
                        <li className="page-item"><a className="page-link" href={anchor}>1</a></li>
                        <li className="page-item active"><a className="page-link" href={anchor}>2</a></li>
                        <li className="page-item"><a className="page-link" href={anchor}>3</a></li>
                        <li className="page-item">
                           <a className="page-link" href={anchor}><i className="icofont-long-arrow-right"></i></a>
                        </li>
                     </ul>
                  </nav>
               </div>
            </div> */}
         </div>
      </section>
      </div>
   );
  }
}

export default Blogfull;
