import React, { Component } from 'react';
import { HashLink as HLink } from 'react-router-hash-link';
import ScrollLink from './ScrollLink';


class Header extends Component {

   render() {
      let imgattr = 'image'

      return (
      <div>
      <header id="home">
         <nav className="navbar navbar-inverse navbar-expand-lg header-nav fixed-top light-header" style={{backgroundColor: "white"}}>
            <div className="container">
               <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
                  <img src={process.env.PUBLIC_URL + '/assets/img/logo.png' } alt={imgattr} width={70} height={73} />
               </a>
               <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCodeply">
               <i className="icofont-navigation-menu"></i>
               </button>
               <div className="collapse navbar-collapse" id="navbarCodeply">
                  <ul className="nav navbar-nav ml-auto">
                     <li><HLink className="nav-link" smooth to={`/`}>Home</HLink> </li>
                     <li>
                         <HLink className="nav-link" to="/about-2">About Us</HLink>

                        <span className="sub-menu-toggle" role="navigation" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <i className="icofont-rounded-down" style={{color:'green'}}></i>
                        </span>
                        <ul className="sub-menu sub-menu-2">
                           <li><HLink className="nav-link" smooth to={`about-2/#ourValues`}>Our Values</HLink></li>
                           <li><HLink className="nav-link" smooth to={`about-2/#ourSet-up`}>Our Set-Up</HLink></li>
                           <li><HLink className="nav-link" smooth to={`about-2/#ourBeliefs`}>Our Beliefs</HLink></li>
                           <li><HLink className="nav-link" smooth to={`about-2/#ourBearers`}>Our Bearers</HLink></li>
                        </ul>
                     </li>
                     <li><HLink className="nav-link" smooth to={`/#news`}>News & Events</HLink></li>
                     <li>
                         <ScrollLink className="nav-link" >Knowledge Based</ScrollLink>

                        <span className="sub-menu-toggle" role="navigation" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <i className="icofont-rounded-down" style={{color:'green'}}></i>
                        </span>
                        <ul className="sub-menu sub-menu-3">
                           <li><HLink to="/Blogfull">H.A.S</HLink></li>
                           <li><HLink to="/htap">H.T.A.P</HLink></li>
                           {/* <li><HLink to="/single-right-sidebar">Single right sidebar</HLink></li> */}
                        </ul>
                     </li>
                     
                     <li><HLink className="nav-link" to={`/sertu`}>e-sertu</HLink></li>
                     <li><HLink className="nav-link" smooth to={`/#contact`}>Contact Us</HLink></li>
                  </ul>
               </div>
            </div>
         </nav>
      </header>      
      </div>

    );
   }
}

export default Header;
